/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import socketClient from 'socket.io-client';
import getEnvVarByName from 'helpers/getEnvVarByName';
import { getToken } from 'auth/AuthService';

const SocketContext = createContext({});
const socketDomain = getEnvVarByName('API_SOCKET');

const SocketProvider = ({ children }) => {
  const { userStore } = useSelector(({ userStore }) => ({
    userStore,
  }));

  const [socket, setSocketClient] = useState(null);
  const [connectedStatus, setConnectedStatus] = useState(false);

  const loginSocketServer = () => {
    socket.emit('user:login', {
      email: userStore?.userData?.email,
      username: userStore?.currentUser?.username,
    });
    //SET_CONNECTED_STATUS
    setConnectedStatus(socket.connected);
  };

  const connectRetry = () => {
    loginSocketServer();
  };

  const joinOrLeaveOfferList = (joinOrLeave) => {
    if (socket) {
      socket.emit('user:joinOrLeaveOfferList', {
        joinOrLeave,
      });
    }
  };

  const joinOrLeaveDealDetail = (joinOrLeave, dealId) => {
    if (socket) {
      socket.emit('user:joinOrLeaveDealDetail', {
        joinOrLeave,
        dealId,
      });
    }
  };

  //CONNECT_SOCKET_SERVER
  useEffect(() => {
    setSocketClient(
      socketClient(socketDomain, {
        path: '/socket',
        auth: {
          token: `Bearer ${getToken()}`,
        },
        transports: ['websocket'],
        reconnectionDelay: 500,
        // upgrade: false,
        // rememberUpgrade: true,
      }),
    );
  }, []);

  //SOCKET_EVENTS_RETRY
  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        //ON_RECONNET_IF_SERVER_DOWN
        connectRetry();
      });
      socket.on('disconnect', function () {
        setConnectedStatus(socket.connected);
      });
      return () => {
        socket.off('connect');
      };
    }
  }, [socket?.connected]);

  console.log('socket', socket);
  console.log('connectedStatus', connectedStatus);

  const contextValues = {
    socket,
    loginSocketServer,
    connectedStatus,
    joinOrLeaveOfferList,
    joinOrLeaveDealDetail,
  };

  return (
    <SocketContext.Provider value={contextValues}>
      {children}
    </SocketContext.Provider>
  );
};

function useSocketContext() {
  const context = useContext(SocketContext);
  return context;
}

export { SocketProvider, useSocketContext };
