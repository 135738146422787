import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { DSThemeProvider } from '@mobiauto/design-system-web-v2/core/theme';
import CssBaseline from '@mui/material/CssBaseline';
import store from 'store';
import routes from 'config/routes';
import LoadingFullScreen from 'components/atoms/LoadingFullScreen';
import UserLocationContextProvider from 'context/UserLocationContext';
import ScrollToTop from 'helpers/ScrollToTop';
import 'moment/locale/pt-br';
import Snackbar from 'components/atoms/Snackbar';
import { defaultTheme } from 'styles/theme';

function App() {
  return (
    <>
      <Provider store={store}>
        <DSThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <BrowserRouter>
            <UserLocationContextProvider>
              <Suspense fallback={<LoadingFullScreen />}>
                <ScrollToTop />
                <Routers />
              </Suspense>
            </UserLocationContextProvider>

          </BrowserRouter>
        </DSThemeProvider>
        <Snackbar />
      </Provider>
    </>
  );
}

function Routers() {
  let element = useRoutes([...routes]);

  return element;
}

export default App;
